import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { Chip } from '@mui/material';
const columns = [
  { field: 'id', headerName: 'Trn No', width: 50 },
  { field: 'created', headerName: 'Date', width: 130 },
  {field:'day',headerName:"Since",width:160,renderCell:(params)=>{
    const now = new Date(); // Current date and time
const target = new Date(params.row.created); // Convert the target date to a Date object

// Calculate the difference in milliseconds
const differenceInMilliseconds = target - now;

// Convert milliseconds to days, hours, minutes
const millisecondsInADay = 1000 * 60 * 60 * 24;
const millisecondsInAnHour = 1000 * 60 * 60;
const millisecondsInAMinute = 1000 * 60;

const days = Math.floor(differenceInMilliseconds / millisecondsInADay);
const hours = Math.floor((differenceInMilliseconds % millisecondsInADay) / millisecondsInAnHour);
const minutes = Math.floor((differenceInMilliseconds % millisecondsInAnHour) / millisecondsInAMinute);
return ` Days: ${days} Hours: ${hours} minutes ${minutes}`
}},
  { field: 'unit', headerName: 'Unit', width:130, renderCell: (params) => params.row.branch?.unit},
  { field: 'branch', headerName: 'Branch', width:130, renderCell: (params) => params.row.branch?.branch},
  { field: 'type', headerName: 'Type', width: 70 },
  { field: 'itemcategory', headerName: 'Item Category', width: 130,  renderCell: (params) =>params.row.itemcategory?.name || ''  },
  { field: 'itemfamily', headerName: 'Item Family', width: 130,  renderCell: (params) =>params.row.itemfamily?.name || ''  },
  {
    field: 'itemtype',
    headerName: 'Item Types',
    width: 130,
    renderCell: (params) =>params.row.itemtype?.name || 'No types' 
  },
  { field: 'subtype1', headerName: 'Sub Type1', width: 130,renderCell: (params) =>params.row.subtype1?.name || ''  },
  { field: 'subtype2', headerName: 'Sub Type2', width: 130,renderCell: (params) =>params.row.subtype2?.name || ''  },
  { field: 'materialg', headerName: 'Material Grade', width: 130,renderCell: (params) =>params.row.materialg?.name || ''  },
  { field: 'mtofconst', headerName: 'Metal of construction', width: 130,  renderCell: (params) =>params.row.mtofconst?.name || ''  },
  { field: 'dimension', headerName: 'Dimension', width: 130,renderCell: (params) =>params.row.dimension?.name || '' },
  { field: 'itemmake', headerName: 'Item make', width: 130,renderCell: (params) =>params.row.itemmake?.name || ''  },
  { field: 'techspec', headerName: 'Tech spec', width: 130,renderCell: (params) =>params.row.techspec?.name || ''  },
  { field: 'otherdetails', headerName: 'Other Details', width: 130,renderCell: (params) =>params.row.otherdetails?.name || ''  },
  { field: 'specification', headerName: 'Specification', width: 130,renderCell: (params) =>params.row.specification?.name || ''  },
  { field: 'process', headerName: 'Process', width: 130, renderCell: (params) =>params.row.process?.name || ''  },
  { field: 'stage', headerName: 'Stage', width: 130, renderCell: (params) =>params.row.stage?.name || ''  },
  { field: 'supplytype', headerName: 'Supply Type', width: 130, renderCell: (params) =>params.row.supplytype?.name || ''   },
  { field: 'stockingtype', headerName: 'Stocking Type', width: 130, renderCell: (params) =>params.row.stockingtype?.name || ''   },
  { field: 'glclass', headerName: 'GL Class', width: 130,renderCell: (params) =>params.row.glclass?.name || ''   },
  { field: 'uom', headerName: 'UOM', width: 130, renderCell: (params) =>params.row.uom?.name || ''  },
  { field: 'linetype', headerName: 'Line Type', width: 130,renderCell: (params) =>params.row.linetype?.name || ''   },
  { field: 'hsn', headerName: 'HSN', width: 130,renderCell: (params) =>params.row.hsn?.name || ''   },
  { field: 'commodity', headerName: 'Commodity', width: 130,renderCell: (params) =>params.row.commodity?.name || ''   },
  { field: 'subcommodity', headerName: 'Sub Commodity', width: 130,renderCell: (params) =>params.row.subcommodity?.name || ''   },
  { field: 'itemno', headerName: 'Item Code', width: 130,renderCell: (params) =>params.row.itemno  },
  { field: 'description', headerName: 'Description', width: 130,renderCell: (params) =>params.row.description || ''   },
  { field: 'admin', headerName: 'Admin', width: 130,renderCell: (params) =>{
    if (params.row.admin) {
      return <div>
      <Chip label="Approved" color="success" />
   </div>;
    } else if (params.row.adminrejected) {
      return `${params.row.adminRemarks}`;
    } else {
      return <Chip label="Pending" color="primary" variant='outlined' />
    }
  }
},
  { field: 'operation', headerName: 'Operation', width: 130,renderCell: (params) =>{
      if (params.row.operation) {
        return <div>
        <Chip label="Approved" color="success" />
     </div>;
      } else if (params.row.orejected) {
        return `${params.row.operationRemarks}`;
      } else {
        return <Chip label="Pending" color="primary" variant='outlined' />
      }
    }   
  },
  { field: 'accounts', headerName: 'Accounts', width: 130,renderCell: (params) =>{
    if (params.row.account) {
      return <div>
        <Chip label="Approved" color="success" />
     </div>;
    } else if (params.row.arejected) {
      return  `${params.row.accountremarks}`;
    } else {
      return <Chip label="Pending" color="primary" variant='outlined' />
    }
  }   
},
  
];



function RequitionList(props) {
  const [reqlist,setReqList] = useState(null)
 



const handleCellClick = (params)=>{
  console.log("cell is ",params)
  if(params.row.admin){
    toast.warning("Can't edit now.")
  }
 else{
  props.onSendData(params.row)
 }
  
  }
  

  useEffect(()=>{
    let isMounted = true;
    const cookies = new Cookies()
    const token = cookies.get('access')
    const res =  axios.get("/requisition/", {
      headers: {
          'Authorization': `Bearer ${token}`
      },
      withCredentials: true
  });
  res
  .then((response)=>{
    if(response.status === 200 && isMounted===true){
      console.log(response.data);
      setReqList(response.data.rq);
    }
  })
  .catch((error)=>{
    console.log(error)
  })

  return ()=>{
    isMounted = false;
  }
  },[])
  return (
    <div id='rqlist'>
    

      <div style={{ height: 500, width: '100%' }}>

        <DataGrid
            rows={reqlist}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5, 10, 50]}
            onCellClick={handleCellClick}
           
        />
      </div>
     
    </div>
  )
}

export default RequitionList