import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { TextField, Autocomplete } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaWindowClose } from 'react-icons/fa';





function NewRequisition(props) {
    const [loading,setLoading] = useState(true)
    const [type,setType] = useState('direct')
    const [open,setOpen] = useState(false)
    const [found,setFound] = useState(false)
    const sl = useRef(null)

    const cookies = new Cookies()
   const familyids = [70,72,55,56,57,59,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94]
   const itemOption = useRef()
    const [selectValue,setSelectValue] = useState(
      {
        "id":"",
        "type":type,
        "itemcategory":"",
        "itemfamily":"",
        "itemtype":"",
        "subtype1":"",
        "subtype2":"",
        "mtofconst":"",
        "materialg":"",
        "specification":"",
        "process":"",
        "stage":"",
        "supplytype":"",
        "itemmake":"",
        "techspec":"",
        "dimension":"",
        "otherdetails":"",
        "stockingtype":"",
        "glclass":"",
        "uom":"",
        "linetype":"",
        'hsn':'',
        "commodity":"",
        "subcommodity":"",
        "slno":"",
        "itemno":'',
        "description":"",
        "temp":""
      }
    )

    // const sortAlphabetically = (array) => array.slice().sort((a, b) => a.localeCompare(b));
    const ncookies = new Cookies()
    const ntoken = ncookies.get('access')
    const handleRadioButton = (event)=>{
        var radiovalue = event.target.value;
        setType(radiovalue)
        setSelectValue({
          ...selectValue,
          type:radiovalue
        })
       
    
    }
    
    const handleUserForm = (event)=>{
        event.preventDefault();
        const formData = new FormData(event.target); // Create a FormData object from the form
        const data = Object.fromEntries(formData.entries());
        console.log(data)
  
        // Convert the FormData to an object
        
      }
      function splitString(str, chunkSize) {
        let result = [];
        let startIndex = 0;
    
        while (startIndex < str.length) {
            let endIndex = startIndex + chunkSize;
    
            // If the substring is at or less than the chunkSize, no need to adjust
            if (endIndex < str.length) {
                // Find the last space within the chunkSize limit
                let lastSpaceIndex = str.lastIndexOf(' ', endIndex);
    
                // If there's no space found, just break at the chunkSize limit
                if (lastSpaceIndex === -1 || lastSpaceIndex <= startIndex) {
                    lastSpaceIndex = endIndex;
                }
    
                result.push(str.slice(startIndex, lastSpaceIndex).trim());
                startIndex = lastSpaceIndex + 1;  // Skip the space
            } else {
                result.push(str.slice(startIndex).trim());
                break;
            }
        }
        return result;
    }
    
    function validateField(obj){
      let indirectFields = [
        'itemcategory',
        'itemfamily',
        'itemtypes',
      ]
      let directFields = [
        'itemcategory',
        'itemfamily',
        'itemtypes',
        'mtofconst',
        'supplytype'
      ]
      
      let requiredFields = ""
      if(type==='direct'){
        requiredFields =directFields;
      }
      else{
        requiredFields = indirectFields;
      }
      for (const field of requiredFields) {
       
        if (obj[field] === null || obj[field] === '') {
          toast.warning(`Field ${field} is required`)
          return false; // Field is null or blank
        }
       
       
      }
    
      return true; // All fields are valid
    }
   
    function validateDescriptionFromJD(itemcode,description){
      let rbtoken = cookies.get('rsbtoken')
      console.log("rsbtoken",rbtoken)
      let payload = JSON.stringify({ 
        "token":rbtoken, 
        "DataInfo": "ITM", 
        "DataValue1":itemcode, 
        "DataValue2": description 
      })
   

      if(rbtoken){
        const res = axios.post("http://192.168.50.158:8073/jderest/orchestrator/IC_DatainfoValidate",payload, {
          headers: { 
            'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*'
        },
        withCredentials: false
        });
        res
        .then((response)=>{
      //     if(res['cReturnFlag']=="0"):
      //      setFound(false)
      //     return Response({"exists":f"Description found as {res['szRemarks']}"})
      // if(res['cReturnFlag']=="1"):
          console.log("jd description  ",response)
          const rec_response = response
          if(rec_response.data['status'] === "ERROR"){
            toast.warning(rec_response.data['message'])
          }
          if(rec_response.status === 200){
            if(rec_response.data['cReturnFlag']==="0"){
              toast.warning(`Description found as ${rec_response.data['szRemarks']}`)
              setFound(true)
              setOpen(true)
              return true
            }
                 
            else if(rec_response.data['cReturnFlag']==="1"){
              toast.success("Description Not found")
              setFound(false)
              setOpen(true)
              return false
            }
            
          }
        
        })
        .catch((error)=>{
          console.log(error)
        })
      }
      else{
      toast.warning("Please generate a token")
      }
      

    }
    function ValidateDescription(itemcode,description){
    
      const res = axios.post("/validatedesc/",{itemcode,description}, {
        headers: {
            'Authorization': `Bearer ${ntoken}`
        },
        withCredentials: true
    });
    res
    .then((response)=>{
      const rec_response = response
      if(rec_response.status === 200){
        if(rec_response.data.exists){
          toast.warning(rec_response.data.exists)
          setFound(true)
          setOpen(true)

          return true
        }
        else if(rec_response.data.not_exists){
          let valid = validateDescriptionFromJD(itemcode,description)
          return valid
         
        }
        
      }
  
    })
    .catch((error)=>{
      console.log(error)
    })
    
    
  }
  
  
 async function getItemRecord(formdata){
    try{
      const res = await axios.post("/reqrecord/",{formdata}, {
        headers: {
            'Authorization': `Bearer ${ntoken}`
        },
        withCredentials: true
    });
  

  return res;
   
 
    }
    catch(error){
      console.log(error)
    }
  }

 function generatecode(){
    setFound(false)
    let vfield = validateField(selectValue)
    function generate(){
      const categ = itemOption.current.item_categories.find(element=>element.id===selectValue.itemcategory)
      const fam = itemOption.current.families.find(element=>element.id===selectValue.itemfamily)
      const itemtp = itemOption.current.item_types.find(element=>element.id===selectValue.itemtype)
      const moc_code =itemOption.current.metals_of_construction.find(element=>element.id===selectValue.mtofconst)
      const process = itemOption.current.processes.find(element=>element.id===selectValue.process)
      const stage = itemOption.current.stages.find(element=>element.id===selectValue.stage)
      const subtype1 = itemOption.current.subtype1.find((element)=>element.id===selectValue.subtype1)
      const subtype2 = itemOption.current.subtype2.find((element)=>element.id===selectValue.subtype2)
      // const specification = itemOption.current.specification.find((element)=>element.id===selectValue.specification)
      const techspec = itemOption.current.technicalspecification.find((element)=>element.id===selectValue.techspec)
      const dimension = itemOption.current.dimensions.find((element)=>element.id===selectValue.dimension)
      const itemmake = itemOption.current.itemmake.find((element)=>element.id===selectValue.itemmake)
      const otherdetail = itemOption.current.otherdetails.find((element)=>element.id===selectValue.otherdetails)
      const itemc = selectValue.itemcategory
      const itemmoc =  selectValue.mtofconst
      const itemf = selectValue.itemfamily
      const itemt = selectValue.itemtype 
      const supplyt = itemOption.current.supply_types.find(element=>element.id===selectValue.supplytype)
      var srlno = ""
      var record = ""
      var item_code = ""
      var descrptn = ""
      if(type === "indirect"){
       
        descrptn = (subtype1?.name??"") + " " + (itemtp?.name??"") + " " + (techspec?.name??"") + (dimension?.name??"") + (itemmake?.name??"") + (otherdetail?.name??"")
        record = getItemRecord({type,itemc,itemf,itemt})
        record
        .then((response)=>{
          console.log(response)
          if(response.status === 200){
            srlno = (Number(response.data.record[0].indirectitemid) + 1).toString().padStart(4, '0')
            if(itemc === 2){
              item_code = categ.assignedNo + fam.assignedNo + itemtp.assignedNo + srlno + process?.assignedNo
            }
            else if(itemc === 3){
              item_code = categ.assignedNo + fam.assignedNo + itemtp.assignedNo + srlno + stage?.assignedNo
              
            }
            else{
              item_code = categ.assignedNo + fam.assignedNo + itemtp.assignedNo + srlno

            }
            if(descrptn.length>60){
              toast.error("Description exceeding 60 character.")
            }
            else{
              const splited_desc = splitString(descrptn,30)
      
              ValidateDescription(item_code,descrptn)
       
              setSelectValue({
                ...selectValue,
                "slno":srlno,
                'itemno':item_code,
                "splited_desc":splited_desc,
                "description":descrptn,

              })
            }
            
           
          }
        })
        .catch((error)=>{
          console.log(error)
        })
       
        
        

      }
      else{
       
        console.log("before item code sl is",sl)
     
        descrptn = (subtype1?.name??"") + " " + (itemtp?.name??"") + " " + (subtype2?.name??"") 
        record = getItemRecord({type,itemc,itemmoc,itemf,itemt});
        record
        .then((response)=>{
          console.log(response)
        
          if(response.status === 200){
            srlno = (Number(response.data.record[0].directitemid) + 1).toString().padStart(4, '0')
            if(itemc === 2){
            item_code = categ?.assignedNo + moc_code?.assignedNo + fam?.assignedNo + itemtp?.assignedNo  + srlno + process?.assignedNo + supplyt.assignedNo
            }
            else if(itemc === 3){
              item_code = categ?.assignedNo + moc_code?.assignedNo + fam?.assignedNo + itemtp?.assignedNo  + srlno + stage?.assignedNo + supplyt.assignedNo
              
            }
            else{
              item_code = categ?.assignedNo + moc_code?.assignedNo + fam?.assignedNo + itemtp?.assignedNo  + srlno + supplyt.assignedNo

            }
            if(descrptn.length>60){
              toast.warning("Description exceeding 60 character.")
            }
            else{
              const splited_desc = splitString(descrptn,30)
      
              ValidateDescription(item_code,descrptn)
       
              setSelectValue({
                ...selectValue,
                "slno":srlno,
                'itemno':item_code,
                "splited_desc":splited_desc,
                "description":descrptn,
              })
             
            }
           


        
           
          }
        })
        .catch((error)=>{
          console.log(error)
        })
        
      }
        
  
    
    
    }
      
    
    vfield?generate()
    : console.log(false)

  }
  async function CreateRequisition(){
    try{
      console.log("Data to save is",selectValue)
     
        let res = await axios.post("/requisition/",{selectValue}, {
          headers: {
              'Authorization': `Bearer ${ntoken}`
          },
          withCredentials: true
      });
      
      
    const rec_response = res
    if(rec_response.status === 200){
      if(rec_response.data.exists){
        toast.warning(rec_response.data.exists)
        setFound(true)
      }
      else if(rec_response.data.not_exists){
        toast.warning(rec_response.data.not_exists)
        setFound(false)
      }
      else if(rec_response.data.valid){
 
        toast.success(rec_response.data.valid)
        console.log(rec_response.data.rq)
        setOpen(false)
      }
      else if(rec_response.data.not_valid){
        toast.warning(rec_response.data.not_valid)
      }
      return rec_response.data
    }
  }
  catch(error){
    console.log(error)
  }
  }



    useEffect(()=>{
      let isMounted = true
        async function MasterItem(){
            const cookies = new Cookies()
            const token = cookies.get('access')
        try{
            const res =  axios.get("/masteritem/", {
              headers: {
                  'Authorization': `Bearer ${token}`
              },
              withCredentials: true
          });
          res
          .then((response)=>{

           if(response.status === 200 && isMounted===true){
            const item = response.data?response.data:null
            console.log("items are",item)
            itemOption.current = item
           
            setLoading(false)
           }
            
          })
          .catch((error)=>{
            console.error("catched error",error)
            if(error.request.status === 0){
              toast.warning("Check network connection.")
            }
            else if(error.response.status === 401 && isMounted === true){
                toast.warning("Session expired")
        
            }
           
            isMounted = false
          })
         
          
          }
          catch(error){
            console.log(error)
            toast.warning("session timeout")
     
          }
        }
        MasterItem()
        return () => {
          isMounted = false; // Cleanup flag on unmount
        };
    },[])

  return (
    <div>
    <Dialog onClose={()=>setOpen(false)} open={open}>
      <DialogTitle>Item Code and Description</DialogTitle>
      <DialogContent>
      {found?
        <Box
      sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
      noValidate
      autoComplete="off"
    >
      <FormGroup>
        <FormControlLabel control={<Checkbox onClick={(e)=>{
          
          setSelectValue({
                ...selectValue,
                "temp":e.target.checked,
          })

          }}/>} label="Temp" />
      </FormGroup>
        {/* <TextField id="initialValue" size="small" label="Initial Value" value={initialValue} onChange={(e)=>setInitialValue(e.target.value)} variant="outlined" /> */}
      {/* <Button variant='contained' onClick={setInitialRecord}>Save</Button> */}

      </Box>
      :""}

     
      </DialogContent>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Item Code</TableCell>
            {selectValue.splited_desc?selectValue.splited_desc.map((element,index)=><TableCell key={index} align="right">Description {index + 1}</TableCell>):""}
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
              key={selectValue.itemno}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {selectValue.itemno}
              </TableCell>
              {selectValue.splited_desc?selectValue.splited_desc.map((element,index)=>  <TableCell key={index} component="th" scope="row">
                {element}
              </TableCell>):""}
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
   
      <Button variant='contained' onClick={CreateRequisition}>Save</Button>
  
    </Dialog>
    {   loading?<div>
    <h3>Loading ...... </h3>
    <FaWindowClose className='float-end text-xl mr-2' onClick={props.toggleDrawer(false)} />
    
    </div>: 
        <form onSubmit={handleUserForm} className="p-2">
          <FaWindowClose className='float-end text-xl mr-2' onClick={props.toggleDrawer(false)} />
        <FormControl >
                
                <RadioGroup
                row
                name="type"
                value={type}    
                onChange={handleRadioButton} 
                >
                    <FormControlLabel value="direct" control={<Radio />} label="Direct" />
                    <FormControlLabel value="indirect" control={<Radio />} label="Indirect" />
                </RadioGroup>
            </FormControl>
              <Box
                
                sx={{
                    '& > :not(style)': { m: 1,gap:'5'
                     },
                     '& .MuiInputBase-root': { 
                                   fontSize: '0.85rem' // Decrease font size for TextField
                    }
                }
                
                }
                noValidate
                autoComplete="off"
                >
                <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemcategory_autocomplete"
                      options={itemOption.current.item_categories.filter((current)=>current.type===type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.item_categories.find((item) => item.id === selectValue.itemcategory) || null} // determine the selected value
                      onChange={(event, newValue) => {
                      if(newValue ===null){
                        setSelectValue({ ...selectValue, itemcategory: newValue ? newValue.id : '' });
                      }
                      else{
                       
                    
                        setSelectValue({ ...selectValue, itemcategory: newValue ? newValue.id : '' });
                   
                      }
                      
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Category"  />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemfamily_autocomplete"
                      options={itemOption.current.families.filter(element=>element.type === type && element.category === selectValue.itemcategory)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.families.find((item) => item.id === selectValue.itemfamily) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        if(newValue === null){
                        setSelectValue({ ...selectValue, itemfamily: newValue ? newValue.id : '' });

                        }
                        else{
                        
                        
                        setSelectValue({ ...selectValue, itemfamily: newValue ? newValue.id : '' });
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Family" />
                      )}
                    />
                  
                  
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemtypes_autocomplete"
                      options={itemOption.current.item_types.filter(element=>element.type === type && element.family === selectValue.itemfamily)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.item_types.find((item) => item.id === selectValue.itemtype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setSelectValue({ ...selectValue, itemtype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Types" />
                      )}
                    />
                  
                 
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subtype1_autocomplete"
                      options={itemOption.current.subtype1.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.subtype1.find((item) => item.id === selectValue.subtype1) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setSelectValue({ ...selectValue, subtype1: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Type1" />
                      )}
                    />
                    <div>
                    {/* <TextField id="subtype1" size="small" className='w-full' label="Sub Type 1" value={selectValue.subtype1} onChange={(e)=>{setSelectValue({...selectValue,subtype1:e.target.value})}} variant="outlined" /> */}
                    </div>
    
                  {
                    type === 'direct'? 
                    <div>
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subtype2_autocomplete"
                      options={itemOption.current.subtype2.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.subtype2.find((item) => item.id === selectValue.subtype2) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, subtype2: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Type2" />
                      )}
                    />
                    {/* <TextField id="subtype2" size="small" className='w-full' label="Sub Type 2" value={selectValue.subtype2} onChange={(e)=>{setSelectValue({...selectValue,subtype2:e.target.value})}} variant="outlined" /> */}
                    </div>
    
                    :""
                }
                {
                  type === "direct"?
                  <div>
                  <Autocomplete
                    disablePortal
                    size="small"
                    sx={{marginTop:"10px"}}
                    classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                    id="mtgrade_autocomplete"
                    options={itemOption.current.materialgrade} // the options prop expects an array of objects
                    getOptionLabel={(option) => option.name} // function to display option label
                    value={itemOption.current.materialgrade.find((item) => item.id === selectValue.materialg) || null} // determine the selected value
                    onChange={(event, newValue) => {
                      setSelectValue({ ...selectValue, materialg: newValue ? newValue.id : '' });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                    renderInput={(params) => (
                      <TextField {...params} label="Meterial Grade" />
                    )}
                  />
                  <Autocomplete
                  className='mt-2'
                    disablePortal
                    size="small"
                    classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                    id="moc_autocomplete"
                    options={itemOption.current.metals_of_construction} // the options prop expects an array of objects
                    getOptionLabel={(option) => option.name} // function to display option label
                    value={itemOption.current.metals_of_construction.find((item) => item.id === selectValue.mtofconst) || null} // determine the selected value
                    onChange={(event, newValue) => {
                      
                      setSelectValue({ ...selectValue, mtofconst: newValue ? newValue.id : '' });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                    renderInput={(params) => (
                      <TextField {...params} label="Meterial of construction" />
                    )}
                  />
               
                  </div>
                  :""
                  }
                    {
                      type === 'indirect'?
                      <div>
                        <Autocomplete
                          disablePortal
                          size="small"
                          sx={{marginTop:"10px"}}
                          classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                          id="itemmake_autocomplete"
                          options={itemOption.current.itemmake} // the options prop expects an array of objects
                          getOptionLabel={(option) => option.name} // function to display option label
                          value={itemOption.current.itemmake.find((item) => item.id === selectValue.itemmake) || null} // determine the selected value
                          onChange={(event, newValue) => {
                            setSelectValue({ ...selectValue, itemmake: newValue ? newValue.id : '' });
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              )}
                          renderInput={(params) => (
                            <TextField {...params} label="Item Make" />
                          )}
                        />
                      {/* Technical specification dropdown */}
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="technicalspecification_autocomplete"
                        options={itemOption.current.technicalspecification} // the options prop expects an array of objects
                        getOptionLabel={(option) => option.name} // function to display option label
                        value={itemOption.current.technicalspecification.find((item) => item.id === selectValue.techspec) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, techspec: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Technical Specification" />
                        )}
                      />
                      {/* Other detials dropdown */}
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="otherdetails_autocomplete"
                        options={itemOption.current.otherdetails} // the options prop expects an array of objects
                        getOptionLabel={(option) => option.name} // function to display option label
                        value={itemOption.current.otherdetails.find((item) => item.id === selectValue.otherdetails) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, otherdetails: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Other Details" />
                        )}
                      />
                      </div>
                      :""
                    }
                    {
                      selectValue.itemfamily === 63?
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="specification_autocomplete"
                        options={itemOption.current.specification.filter(element=>element.type === type)} // the options prop expects an array of objects
                        getOptionLabel={(option) => option} // function to display option label
                        value={itemOption.current.specification.find((item) => item.id === selectValue.specification) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, specification: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Item Make" />
                        )}
                      />
                      :""
                    }
                  {
                    familyids.includes(selectValue.itemfamily)?
                      <div>
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="dimensions_autocomplete"
                        options={itemOption.current.dimensions} // the options prop expects an array of objects
                        getOptionLabel={(option) => option.name} // function to display option label
                        value={itemOption.current.dimensions.find((item) => item.id === selectValue.dimension) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, dimension: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Dimension" />
                        )}
                      />
                     
                      </div>
                      
                      :""
                    }
                  {
                    selectValue.itemcategory === 2?
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="lastprocess_autocomplete"
                      options={itemOption.current.processes.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.processes.find((item) => item.id === selectValue.process) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, process: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} className='text-sm' label="Last Process" />
                      )}
                    />
                 :""
                  }
                  {
                    selectValue.itemcategory === 3?
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="stages_autocomplete"
                      options={itemOption.current.stages.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.stages.find((item) => item.id === selectValue.stage) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, stage: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Stages" />
                      )}
                    />
                     
                    :""
                  }
                  {
                    type === "direct"?
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="supplytype_autocomplete"
                      options={itemOption.current.supply_types.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.supply_types.find((item) => item.id === selectValue.supplytype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, supplytype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Supply Type" />
                      )}
                    />
                    :""
                    }
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="stockingtype_autocomplete"
                      options={itemOption.current.stocking_types} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name} (${option.assignedNo})`} // function to display option label
                      value={itemOption.current.stocking_types.find((item) => item.id === selectValue.stockingtype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, stockingtype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name} ({option.assignedNo})
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Stocking Type" />
                      )}
                    />
                     <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="glclass_autocomplete"
                      options={itemOption.current.gl_classes} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.assignedNo}`} // function to display option label
                      value={itemOption.current.gl_classes.find((item) => item.id === selectValue.glclass) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, glclass: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.assignedNo}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Gl Class" />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="uom_autocomplete"
                      options={itemOption.current.uoms} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.shortform}`} // function to display option label
                      value={itemOption.current.uoms.find((item) => item.id === selectValue.uom) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, uom: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.shortform}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="UOM" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="hsn_autocomplete"
                      options={itemOption.current.hsn} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.hsn.find((item) => item.id === selectValue.hsn) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, hsn: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="HSN" />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="linetype_autocomplete"
                      options={itemOption.current.line_types} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name} (${option.shortform})`} // function to display option label
                      value={itemOption.current.line_types.find((item) => item.id === selectValue.linetype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, linetype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name} ({option.shortform})
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Line Type" />
                      )}
                    />
                  
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="commodity_autocomplete"
                      options={itemOption.current.commodity} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.commodity.find((item) => item.id === selectValue.commodity) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, commodity: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Commodity Class" />
                      )}
                    />
                 <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subCommodity_autocomplete"
                      options={itemOption.current.subcommodity} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.subcommodity.find((item) => item.id === selectValue.subcommodity) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, subcommodity: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Commodity Sub Class" />
                      )}
                    />
                      <Button variant="contained" onClick={generatecode} >Generate</Button>
                 
             
              
                
              </Box>
      
        </form>
        }
    </div>
  )
}

export default NewRequisition