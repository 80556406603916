import React from 'react'
import NewItem from '../component/NewItem';



function ItemCategory() {
   
  return (
   <>
    <NewItem heading="Item Category" apiurl="/itemcategory/" />
   </>
  )
}

export default ItemCategory